<!--秒杀专区-->
<template>
    <div>
        <!--banner-->
        <div class="top-wrapper">
            <img class="down-load-poster" src="https://seetop-1257860468.file.myqcloud.com/vue/img/dwonloadposter.png" v-if="isDistributionMember == 1 && categoryConfig.poster" @click="viewPoster">
            <van-swipe class="swipe-wrapper" indicator-color="white" >
                <van-swipe-item>
                    <img class="swipe-img" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + categoryConfig['banner']">
                </van-swipe-item>
            </van-swipe>
        </div>
        <product-mid-size-item v-for="(item, index) in productList" :key="index"
                               :isSellOut="item.is_sellout"
                               :onlineStart="item.online_start"
                               :onlineEnd="item.online_end"
                               :orgPrice="item.price"
                               :price="item.mallprice"
                               :name="item.name"
                               :distributorPrice="item.c_level1_money"
                               :img="item.images"
                               :id="item.id"
                               :isDistributor="isDistributionMember"
                               :cellId="item.product_cell_id"
        ></product-mid-size-item>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { getChannelParams, getPostSrcChannel, setShopTitle } from '../../utils/tools'
  import { getCookie } from '../../utils/cookie'
  import { globalConfig, WXJsdk } from '../../utils/mixin'
  import ProductMidSizeItem from '../../components/product/ProductMidSizeItem'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'SeckillGroup',
    components: { FloatNav, ProductMidSizeItem },
    mixins: [globalConfig, WXJsdk],
    data () {
      return {
        categoryId: 1,
        isDistributionMember: 0,
        categoryConfig: [],
        productList: []
      }
    },
    methods: {
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/product/getSeckillCategory', {
          category: this.categoryId,
          srcChannel: getPostSrcChannel()
        }).then((response) => {
          const data = response.data
          const result = data.data
          that.productList = data.data.list
          that.categoryConfig = data.data.config
          that.WxShareConfig(result.shareInfo.title, result.shareInfo.desc, that.createDistributionShareUrl(window.location.href), 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + result.shareInfo.imgUrl)
          // 设置当前店铺名，只有一种可能；用户不是分销员，并且当前渠道存在有效；
          if (that.isDistributionMember == 0 && result.srcChannelShopName) {
            setShopTitle(result.srcChannelShopName)
          }
        })
      },
      viewPoster: function () {
        this.$router.push('/salePoster?categoryId2=' + this.categoryId)
      }
    },
    created () {
      getChannelParams()
      this.categoryId = this.$route.params.id
      this.isDistributionMember = getCookie('isDistributionMember')
      this.init()
    }
  }
</script>

<style scoped>
    .top-wrapper {}
    .down-load-poster {
        width: 3rem;
        height: 3rem;
        position: fixed;
        left: 1.5rem;
        top: 2rem;
        z-index: 99;
    }
    .swipe-wrapper {
        width: 95%;
        margin: 0.5rem auto 0;
    }
    .swipe-wrapper .van-swipe-item {
        width: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;
    }
    .swipe-img {
        width: 100%;
        border-radius: 1rem;
    }
</style>
